var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-homepage-wrapper _grid _grid-cols-1 _grid-cols-24--l _grid-cols-24--xl _row-gap-6",
    },
    _vm._l(_vm.projectExplorerSections, function (section, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "_col-span-1 _col-span-24--l",
          attrs: {
            "data-qa": `homepage-section:${section.props.defaultCategoryKey}`,
          },
        },
        [
          _c(
            "project-explorer",
            _vm._b(
              {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "prefix",
                      fn: function () {
                        return [
                          _c("home-section-title", [
                            _vm._v(_vm._s(section.title)),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              "project-explorer",
              section.props,
              false
            )
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }